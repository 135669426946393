import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import '../styles/app.scss';
import SEO from '../components/seo';

function getElementOffset(el) {
    let top = 0;
    let left = 0;
    let element = el;

    // Loop through the DOM tree
    // and add it's parent's offset to get page offset
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    return {
        top,
        left,
    };
}

const ParentsCorner = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    function handleAccordion(e) {
        const AccId = e.target.getAttribute('data-target');
        const buttonTriggers = document.querySelectorAll('.accordion-trigger');
        const accordionList = document.querySelectorAll('.collapse');

        // remove all arias from buttons
        buttonTriggers.forEach(button => {
            button.removeAttribute('aria-expanded');
            button.classList.add('collapsed');
        });
        e.target.classList.remove('collapsed');

        // hide all except clicked one
        accordionList.forEach(accordion => {
            const accContentId = accordion.getAttribute('id');
            if (accContentId !== AccId) {
                accordion.classList.remove('show');
            }
        });

        // e.target.setAttribute('aria-expanded', 'true');s
        const elementToShow = document.getElementById(AccId);
        const elementOffset = getElementOffset(e.target);

        // show hide element
        if (elementToShow.classList.contains('show')) {
            elementToShow.classList.remove('show');
            e.target.classList.add('collapsed');
        } else {
            elementToShow.classList.add('show');
            // scroll element into view
            window.scrollTo({
                top: elementOffset.top - 250,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt centersec">
                <div className="container">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.acf.faq_description,
                        }}
                    />
                    <img
                        className="schoolsline"
                        src={LineFull}
                        alt="linefull"
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<h2 class="bluetxt">${post.acf.faq_title_1}</h2>`,
                        }}
                    />
                    <div className="accordion" id="accordionfaq1">
                        {post.acf.faq_list_1.map((faq1, i) => {
                            let classCollapse = 'collapse';
                            let buttonCollapse =
                                'btn btn-link collapsed accordion-trigger';
                            if (i === 0) {
                                classCollapse = 'collapse show';
                                buttonCollapse =
                                    'btn btn-link accordion-trigger';
                            }
                            return (
                                <div className="card" key={i}>
                                    <div
                                        className="card-header"
                                        id={`heading_1_${i}`}
                                    >
                                        <h2 className="mb-0">
                                            <button
                                                className={buttonCollapse}
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={`collapse_1_${i}`}
                                                aria-expanded="true"
                                                aria-controls={`collapse_1_${i}`}
                                                onClick={handleAccordion}
                                                dangerouslySetInnerHTML={{
                                                    __html: faq1.faq_question1,
                                                }}
                                            />
                                        </h2>
                                    </div>

                                    <div
                                        id={`collapse_1_${i}`}
                                        className={classCollapse}
                                        aria-labelledby={`heading_1_${i}`}
                                        data-parent="#accordionfaq1"
                                    >
                                        <div
                                            className="card-body"
                                            dangerouslySetInnerHTML={{
                                                __html: faq1.faq_answer1,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<h2 class="bluetxt">${post.acf.faq_title_2}</h2>`,
                        }}
                    />
                    <div className="accordion" id="accordionfaq2">
                        {post.acf.faq_list_2.map((faq2, i) => {
                            let classCollapse = 'collapse';
                            let buttonCollapse =
                                'btn btn-link collapsed accordion-trigger';
                            if (i === 0) {
                                classCollapse = 'collapse show';
                                buttonCollapse =
                                    'btn btn-link accordion-trigger';
                            }
                            return (
                                <div className="card" key={i}>
                                    <div
                                        className="card-header"
                                        id={`heading_2_${i}`}
                                    >
                                        <h2 className="mb-0">
                                            <button
                                                className={buttonCollapse}
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={`collapse_2_${i}`}
                                                aria-expanded="true"
                                                aria-controls={`collapse_2_${i}`}
                                                onClick={handleAccordion}
                                                dangerouslySetInnerHTML={{
                                                    __html: faq2.faq_question2,
                                                }}
                                            />
                                        </h2>
                                    </div>

                                    <div
                                        id={`collapse_2_${i}`}
                                        className={classCollapse}
                                        aria-labelledby={`heading_2_${i}`}
                                        data-parent="#accordionfaq2"
                                    >
                                        <div
                                            className="card-body"
                                            dangerouslySetInnerHTML={{
                                                __html: faq2.faq_answer2,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<h2 class="bluetxt">${post.acf.faq_title_3}</h2>`,
                        }}
                    />
                    <div className="accordion" id="accordionfaq3">
                        {post.acf.faq_list_3.map((faq3, i) => {
                            let classCollapse = 'collapse';
                            let buttonCollapse =
                                'btn btn-link collapsed accordion-trigger';
                            if (i === 0) {
                                classCollapse = 'collapse show';
                                buttonCollapse =
                                    'btn btn-link accordion-trigger';
                            }
                            return (
                                <div className="card" key={i}>
                                    <div
                                        className="card-header"
                                        id={`heading_3_${i}`}
                                    >
                                        <h2 className="mb-0">
                                            <button
                                                className={buttonCollapse}
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={`collapse_3_${i}`}
                                                aria-expanded="true"
                                                aria-controls={`collapse_3_${i}`}
                                                onClick={handleAccordion}
                                                dangerouslySetInnerHTML={{
                                                    __html: faq3.faq_question3,
                                                }}
                                            />
                                        </h2>
                                    </div>

                                    <div
                                        id={`collapse_3_${i}`}
                                        className={classCollapse}
                                        aria-labelledby={`heading_3_${i}`}
                                        data-parent="#accordionfaq3"
                                    >
                                        <div
                                            className="card-body"
                                            dangerouslySetInnerHTML={{
                                                __html: faq3.faq_answer3,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<h2 class="bluetxt">${post.acf.faq_title}</h2>`,
                        }}
                    />
                    <div className="accordion" id="accordionfaq">
                        {post.acf.faq_list.map((faq, i) => {
                            let classCollapse = 'collapse';
                            let buttonCollapse =
                                'btn btn-link collapsed accordion-trigger';
                            if (i === 0) {
                                classCollapse = 'collapse show';
                                buttonCollapse =
                                    'btn btn-link accordion-trigger';
                            }
                            return (
                                <div className="card" key={i}>
                                    <div
                                        className="card-header"
                                        id={`heading_${i}`}
                                    >
                                        <h2 className="mb-0">
                                            <button
                                                className={buttonCollapse}
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={`collapse_${i}`}
                                                aria-expanded="true"
                                                aria-controls={`collapse_${i}`}
                                                onClick={handleAccordion}
                                                dangerouslySetInnerHTML={{
                                                    __html: faq.faq_question,
                                                }}
                                            />
                                        </h2>
                                    </div>

                                    <div
                                        id={`collapse_${i}`}
                                        className={classCollapse}
                                        aria-labelledby={`heading_${i}`}
                                        data-parent="#accordionfaq"
                                    >
                                        <div
                                            className="card-body"
                                            dangerouslySetInnerHTML={{
                                                __html: faq.faq_answer,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default ParentsCorner;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                faq_description
                faq_title
                faq_title_1
                faq_title_2
                faq_title_3
                faq_list {
                    faq_question
                    faq_answer
                }
                faq_list_1 {
                    faq_question1
                    faq_answer1
                }
                faq_list_2 {
                    faq_question2
                    faq_answer2
                }
                faq_list_3 {
                    faq_question3
                    faq_answer3
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
